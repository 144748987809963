<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="save">
                <ValidationObserver ref="observer" v-slot="{ valid, invalid, validated, passes, validate, errors }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('department') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary">mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider
                                        ref="administrator"
                                        rules="required|min:3|max:255"
                                        v-slot="{ errors, valid }"
                                    >
                                        <TextInput
                                            id="item-name"
                                            :value="name"
                                            :error="!valid"
                                            :error-messages="errors"
                                            :label="$t('departments.fields.name')"
                                            :disabled="loading"
                                            @input="name = $event"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider
                                        ref="parent_id"
                                        :rules="'required|integer|oneOf:' + departments.map((department) => department.id).join(',')"
                                        v-slot="{ errors, valid }"
                                    >
                                        <SelectInput
                                            id="item-parent"
                                            :value="parent_id"
                                            :item-text="'name'"
                                            :item-value="'id'"
                                            :error="!valid"
                                            :error-messages="errors"
                                            :label="$t('departments.fields.parent')"
                                            :disabled="loading"
                                            :items="departments"
                                            @input="parent_id = $event"
                                        />
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider
                                        ref="head"
                                        rules="required"
                                        v-slot="{ errors, valid }"
                                    >
                                        <UserSelection
                                            id="item-head"
                                            :label="$t('departments.fields.head')"
                                            :value="head"
                                            :error="!valid"
                                            :errors="errors"
                                            @input="head = $event"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider
                                        ref="members"
                                        rules=""
                                        v-slot="{ errors, valid }"
                                    >
                                        <UserSelection
                                            id="item-members"
                                            :label="$t('departments.fields.members')"
                                            :value="members"
                                            multiple
                                            :error="!valid"
                                            @input="members = $event"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 py-7">
                            <v-btn
                                type="submit"
                                :disabled="invalid || loading"
                                :loading="loading"
                                color="primary" class="infinity_button"
                            >
                                {{ $t('save') }}
                            </v-btn>

                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import TextInput from '@/components/Form/TextInput.vue'
import SelectInput from '@/components/Form/SelectInput.vue'
import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";
import UserSelection from "@/components/Form/UserSelection.vue";
import _ from 'lodash';

export default {
    name: 'DepartmentForm',
    directives: {
        mask,
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        TextInput,
        SelectInput,
        UserSelection
    },
    inject: ['forceRerender'],
    data() {
        return {
            item: null,
            dialogForm: true,
            loading: true,
            progress: 0,
            departments: [],
            valid: false,
            errors: [],
            invalid: false,

            id: 0,
            name: '',
            parent_id: 0,
            head: null,
            members: [],
        }
    },
    watch: {},
    async mounted() {
        let id = parseInt(this.$route?.params?.id);

        if (isNaN(id)) {
            id = 0;
        }

        if (id > 0) {
            this.getItem();
        } else {
            this.loading = false;
        }

        await this.getDepartments();

        if (this.$route.query && this.$route.query.parent_id) {
            let parent_id = parseInt(this.$route.query.parent_id);

            if (parent_id > 0) {
                let parent = _.find(this.departments, {id: parent_id})
                if (parent) {
                    this.parent_id = parent_id;
                }
            }
        }
    },
    methods: {
        getItem() {
            let self = this;
            this.loading = true;
            this.$http.get(`admin/department/${this.$route.params.id}`)
                .then(res => {
                    this.item = res.body;
                    this.id = res.body.id;
                    this.name = res.body.name;
                    this.parent_id = res.body.parent_id;
                    this.head = res.body.head ?? null;
                    this.members = res.body.members ?? null;
                })
                .catch(() => {
                    self.$toastr.error(this.$t('departments.failed_to_get_list_departments'))
                }).finally(() => {
                self.loading = false;
            });
        },

        closeDialogAdd() {
            if (window.history.length > 2) {
                this.$router.back()
            } else {
                this.$router.push({
                    name: 'department',
                })
            }

        },

        save() {
            let data = {
                name: this.name,
                parent_id: this.parent_id > 0 ? this.parent_id : null,
                head_id: this.head.id,
                members: this.members.map((member) => member.id),
            };

            if (this.id > 0) {
                let url = `admin/department/${this.id}`;

                this.$http.put(url, data)
                    .then(function () {
                        this.$toastr.success(this.$t("departments.successfully_saved"));
                        this.closeDialogAdd();
                    }).catch(function (resp) {
                        this.$toastr.error(this.$t("departments.error_update_department"));

                        if (resp.body.message) {
                            this.$toastr.error(resp.body.message);
                        }
                    });
            } else {
                let url = 'admin/department';

                this.$http.post(url, data)
                    .then(function () {
                        this.$toastr.success(this.$t("departments.successfully_saved"));
                        this.closeDialogAdd();
                    }).catch(function (resp) {
                        this.$toastr.error(this.$t("departments.failed_to_save"));

                        if (resp.body.message) {
                            this.$toastr.error(resp.body.message);
                        }
                    });
            }
        },

        async getDepartments() {
            let params = {};

            await this.$http
                .get(`admin/department`, {
                    params: params,
                })
                .then(res => {
                    let deps = res.body.data;

                    deps = buildTree(deps, 0, 'id', 'parent_id', 'children');
                    deps = buildListArrayFromTreeArray(deps);

                    for (let i in deps) {
                        let department = deps[i];
                        department.name = (' . '.repeat((department.depth ?? 1))) + department.name
                    }

                    deps.unshift({
                        id: 0,
                        name:
                            this.$t('departments.top_lvl')
                    });

                    this.departments = deps;
                })
                .catch(() => {
                    this.departments = [];
                    this.$toastr.error(this.$t('failed_to_get_list_departments'))
                });
        },
    }
}
</script>
